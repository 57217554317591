<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :zoneFilterShow="zone_agency_filter"
          :agencyFilterShow="zone_agency_filter"
          :locationFilterShow="true",
          :roleFilterShow="true",
          :processFilterShow="true",
          :supervisorFilterShow="true",
          :dateRangeFilterShow="true",
          :locationDefaultValue="true",
          @changeFilter="updateFilter",
          @supervisors="updateSupervisors"
        )
  
  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          color="#498ceb"
        )

  div(v-else)
    b-row(v-if="!qEvents")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_events_found') }}

    div(v-else)
      b-row
        b-col(md="6")
          support-tracker(v-if="trackerData.total", :tracker-data="trackerData")
        b-col(md="6")
          bar-chart-progress(v-if="trackerData.total", :progress-data="progressCompliance")
      
      //- b-row
        b-col(cols="12")
          revenue-report(
            v-if="globalCompliance.labels && globalCompliance.labels.length",
            :location-data="globalCompliance"
          )

      b-row
        b-col(cols="12")
          bar-chart-compliance(
            v-if="locationCompliance.labels && locationCompliance.labels.length",
            :compliance-data="locationCompliance"
          )

      //- b-row
        b-col(
          md="6",
          v-for="(location, name, index) in locationCompliance",
          :key="index"
        )
          earnings-chart(:location-data="location")

      //- b-card
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('message.tableHeader.supervisor') }}
              th {{ $t('message.tableHeader.location') }}
              th {{ $t('message.tableHeader.cumplimiento') }}
              th {{ $t('message.tableHeader.realizadas') }}
              th {{ $t('message.tableHeader.pendientes') }}
              th {{ $t('message.tableHeader.vencidas') }}
              th {{ $t('message.tableHeader.observed') }}
            b-tr(v-for="(supervisor, index) of supervisors", :key="index")
              b-td {{ supervisor.supervisor }}
              b-td {{ supervisor.location }}
              b-td {{ supervisor.compliance }}
              b-td {{ supervisor.done }}
              b-td {{ supervisor.pending }}
              b-td {{ supervisor.overdue }}
              b-td {{ supervisor.observed }}
      
      b-card
        b-table(
          :items="supervisors"
          :fields="tableHeaders"
          hover
          responsive
        )

      b-button(class="mr-4 mb-1", :disabled="!supervisors.length")
        json-excel(:data="supervisors", :fields="supervisorFields")
          | {{ $t('download_xlsx_compliance') }}
      b-button(class="mr-4 mb-1", :disabled="!confirmations.length")
        json-excel(:data="confirmations", :fields="confirmationFields")
          | {{ $t('download_xlsx_confirmations') }}
      b-button(class="mr-4 mb-1", :disabled="!deletedEvents.length")
        json-excel(:data="deletedEvents", :fields="deletedEventsFields")
          | {{ $t('download_xlsx_deleted_confirmations') }}
</template>

<script>
import { queryEventsScore } from "@/@core/queries/calendar";
import { onMounted, ref, computed } from "@vue/composition-api/dist/vue-composition-api";
import axios from "@axios";
import useNotifications from "@/composables/useNotifications";
import { BTable, BTableSimple, BThead, BTr, BTd, BCard } from "bootstrap-vue";
import JsonExcel from "vue-json-excel";
import SupportTracker from "../charts/CardAnalyticSupportTracker.vue";
import BarChartProgress from '../charts/ChartjsHorizontalBarChartProgress.vue'
// import EarningsChart from "../charts/CardAnalyticEarningsChart.vue";
// import RevenueReport from "../charts/CardAnalyticRevenueReport.vue";
import BarChartCompliance from '../charts/ChartjsBarChartCompliance.vue'
import Filters from "@/views/organization/Filters.vue";
import i18n from '@/libs/i18n'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from "@/store";
import useCommon from '@/views/organization/useCommon'
import { get } from 'idb-keyval';

export default {
  components: {
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    JsonExcel,
    SupportTracker,
    BarChartProgress,
    // EarningsChart,
    // RevenueReport,
    BarChartCompliance,
    Filters,
    Loading,
  },
  setup() {
    const userData = store.state?.userStore?.userData;
    const clientId = userData.role !== "admin" ? userData.client.$oid : null;
    const userLocations = userData.locations?.length ? userData.locations : null;
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { handleError } = useCommon()
    const qEvents = ref(0)
    const confirmations = ref([]);
    const supervisors = ref([]);
    const deletedEvents = ref([])
    const globalCompliance = ref({});
    const locationCompliance = ref({});
    const progressCompliance = ref({});
    const trackerData = ref({});
    const locationFilter = ref(userLocations ? userLocations.map(e => e.value) : []);
    const roleFilter = ref([]);
    const processFilter = ref([]);
    const supervisorFilter = ref([]);
    const zoneFilter = ref([]);
    const agencyFilter = ref([]);
    const supervisorsDropdown = ref([])
    get('supervisors').then(response => {
      if (response) supervisorsDropdown.value = response
      else if (localStorage.supervisors) supervisorsDropdown.value = JSON.parse(localStorage.supervisors)
    })
    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)
    const confirmationFields = ref({})
    const isLoading = ref(true)
    const { zone_agency_filter, default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const supervisorFields = ref({
      [i18n.t('message.tableHeader.supervisor')]: "supervisor",
      [i18n.t('message.tableHeader.location')]: "location",
      [i18n.t('message.tableHeader.cumplimiento')]: "compliance",
      [i18n.t('message.tableHeader.realizadas')]: "done",
      [i18n.t('message.tableHeader.pendientes')]: "pending",
      [i18n.t('message.tableHeader.vencidas')]: "overdue",
      [i18n.t('message.tableHeader.observed')]: "observed",
    })
    const deletedEventsFields = ref({
      [i18n.t('message.tableHeader.date')]: "startString",
      [i18n.t('message.tableHeader.worker')]: "worker",
      [i18n.t('message.tableHeader.location')]: "location",
      [i18n.t('message.tableHeader.process')]: "process",
      [i18n.t('message.tableHeader.status')]: "status",
      [i18n.t('message.tableHeader.deletedBy')]: "deletedBy",
      [i18n.t('message.tableHeader.deletedJustification')]: "deletedJustification",
      [i18n.t('message.tableHeader.id')]: "id",
    })
    const { processAdherence, getDatesFromRange, dateDiffInDays, updateLocationConditions } = useCommonDashboards()

    // Table Headers
    const tableHeaders = computed(() => {
      return [
        {
          key: 'supervisor',
          label: i18n.t('label.evaluator'),
          sortable: true,
        },
        {
          key: 'location',
          label: i18n.t('message.tableHeader.location'),
          sortable: true,
        },
        {
          key: 'compliance',
          label: i18n.t('message.tableHeader.cumplimiento'),
          sortable: true,
        },
        {
          key: 'done',
          label: i18n.t('message.tableHeader.realizadas'),
          sortable: true,
        },
        {
          key: 'pending',
          label: i18n.t('message.tableHeader.pendientes'),
          sortable: true,
        },
        {
          key: 'overdue',
          label: i18n.t('message.tableHeader.vencidas'),
          sortable: true,
        },
        {
          key: 'observed',
          label: i18n.t('message.tableHeader.observed'),
          sortable: true,
        },
      ]
    })

    async function listConfirmations() {
      isLoading.value = true
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

      let conditions = {
        client_id: { _id: clientId },
        extendedProps: {
          calendar_in: ["Pendientes", "Realizadas", "Vencidas"],
        },
        start_gte: startFilter,
        start_lt: endFilter,
        OR: [
          { attendee: { AND: [{ OR: [{ deleted: false }, { deleted_exists: false }] }] } },
          { 
            attendee: { AND: [{ deleted: true }] },
            extendedProps: { calendar: "Realizadas"}
          },
          { AND: [{ attendee_exists: false }]}
        ],
      }

      if (supervisorFilter.value.length > 0) {
        conditions.OR[0].attendee.AND.push({supervisors: {_id_in: supervisorFilter.value}})
        conditions.OR[1].attendee.AND.push({supervisors: {_id_in: supervisorFilter.value}})
        // const supervisorFound = supervisorsDropdown.value.find(e => e.value === supervisorFilter.value)
        // const supervisorLocationsQuery = supervisorFound.locationIds?.map(e => ({locations_in: {_id: e}}))
        // conditions.OR[2].AND.push({organizer: {OR: supervisorLocationsQuery}})
      }
      
      if (locationFilter.value.length > 0) updateLocationConditions(locationFilter.value, "_id", conditions)
      if (zoneFilter.value.length > 0) updateLocationConditions(zoneFilter.value, "zone", conditions)
      if (agencyFilter.value.length > 0) updateLocationConditions(agencyFilter.value, "agency", conditions)

      if (roleFilter.value.length > 0) {
        conditions.attendee = {roles_in: roleFilter.value}
      }

      if (processFilter.value.length > 0) {
        conditions.process = {_id_in: processFilter.value}
      }

      axios
        .post("/graphql", {
          query: queryEventsScore,
          variables: { query: conditions, limit: 10000 },
        })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
          qEvents.value = data?.data?.events?.length
          parseConfirmationData(data);
          parseSupervisorData(data, startFilter, endFilter);
        })
        .catch((error) => {
          console.log(error);
          handleError({ error, defaultMessage: i18n.t('message.err_confirmation_list') })
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    // Data for adherence XLSX File
    function parseConfirmationData(data) {
      confirmationFields.value = {
        [i18n.t('message.tableHeader.date')]: "date",
        [i18n.t('message.tableHeader.worker')]: "worker",
        [i18n.t('message.tableHeader.observer')]: "observer",
        [i18n.t('message.tableHeader.location')]: "location",
        [i18n.t('message.tableHeader.process')]: "process",
        [i18n.t('message.tableHeader.score')]: "score",
        [i18n.t('message.tableHeader.total')]: "total",
        [i18n.t('message.tableHeader.adherence')]: "adherence",
        [i18n.t('message.tableHeader.duration')]: "duration",
        [i18n.t('message.tableHeader.comments')]: "comments"
      }
      let confirmations_data = []
      for (const event of data.data.events) {
        if (event.confirmation && !event.confirmation.pending && event.confirmation.total) {
          let comments = ""
            if(event?.confirmation?.activities[event.confirmation.activities?.length - 1]){
              for (let comentario in event?.confirmation.activities[event?.confirmation.activities.length - 1]?.behaviours){
                if (event?.confirmation.activities[event?.confirmation.activities.length - 1].behaviours[comentario].type === "text"){
                  comments = event?.confirmation.activities[event?.confirmation.activities.length - 1].behaviours[comentario].answer
                }
              }
            }
          let payload = {
            date: new Date(event.confirmation.date).toLocaleDateString(`${default_language || 'en'}-US`),
            worker: event.attendee?.name,
            observer: event.confirmation.supervisor?.name,
            location: event.attendee?.locations?.map(e => e.location).join(" / "),
            process: event.process?.name,
            score: event.confirmation.score,
            total: event.confirmation.total,
            adherence: processAdherence(event.confirmation.score, event.confirmation.total),
            duration: event.confirmation.duration,
            id: event.confirmation._id,
            comments: comments
          }

          // Add metadata fields if applicable
          if (event.confirmation.metadata) {
            event.confirmation.metadata.forEach(e => {
              payload[e.name] = e.answer
              if (!confirmationFields.value.hasOwnProperty(i18n.t(`metadata.${e.name}`))) {
                confirmationFields.value[i18n.t(`metadata.${e.name}`)] = e.name
              }
            })
          }
          confirmations_data.push(payload);
        }
      }
      confirmations_data.sort(function (a, b) {
        if (b.score === 0 && a.score === 0) return a.total - b.total
        return b.adherence - a.adherence
      })
      confirmations.value = confirmations_data
      confirmationFields.value[i18n.t('message.tableHeader.id')] = "id"
    }

    // Data for supervisors compliance table
    function parseSupervisorData(data, startFilter, endFilter) {
      let supervisorData = {};
      let _globalCompliance = { Realizadas: 0, Vencidas: 0, Pendientes: 0 };
      let _locationCompliance = {};
      let deletedEventsData = []

      // TODO: change the supervisor from "organizer" to array of supervisord from atendee.
      for (const element of data.data.events) {
        const status = element.extendedProps?.calendar;
        const eventLocations = element.attendee ? element.attendee.locations : element.organizer?.locations

        if (element.deleted) {
          deletedEventsData.push({
            start: new Date(element.start),
            startString: new Date(element.start).toLocaleDateString(`${default_language || 'en'}-US`),
            process: element.process?.name,
            worker: element.attendee?.name,
            location: eventLocations?.map(e => e.location).join(" / "),
            status: status,
            deletedBy: element.deletedBy?.name,
            deletedJustification: element.deletedJustification,
            id: element._id,
          })
          continue
        }

        if (status === "Realizadas") _globalCompliance["Realizadas"] += 1;
        else if (status === "Pendientes") _globalCompliance["Pendientes"] += 1;
        else if (status === "Vencidas") _globalCompliance["Vencidas"] += 1;

        if (eventLocations === undefined) continue;

        for (const location of eventLocations) {
          if (locationFilter.value.length && !locationFilter.value.includes(location._id)) continue
          if (!_locationCompliance[location.location]) {
            _locationCompliance[location.location] = {
              done: 0,
              pending: 0,
              overdue: 0,
              id: location._id
            };
          }
  
          if (status === "Realizadas") _locationCompliance[location.location]["done"] += 1;
          else if (status === "Pendientes") _locationCompliance[location.location]["pending"] += 1;
          else if (status === "Vencidas") _locationCompliance[location.location]["overdue"] += 1;
        }
        
        // Iterate supervisors
        // If the event has an attendee, iterate over its supervisors
        // Else iterate over the list of supervisors and see who has the same location as the event organizer
        if (element.attendee) {
          if (supervisorFilter.value.length) {
            supervisorFilter.value.forEach(s => {
              const supervisorFound = element.attendee.supervisors.find(e => e._id === s)
              if (supervisorFound) {
                const name = supervisorFound.name
                const supervisorLocations = supervisorFound.locations?.map(e => e.location).join(" / ")
                updateSupervisorData(supervisorData, name, supervisorLocations, status)
              }
            })
          } else {
            for (const supervisor of element.attendee.supervisors) {    
              const name = supervisor.name
              const supervisorLocations = supervisor.locations?.map(e => e.location).join(" / ")
              updateSupervisorData(supervisorData, name, supervisorLocations, status)
            }
          }
        } else {
          if (supervisorFilter.value.length) {
            supervisorFilter.value.forEach(s => {
              const supervisorFound = supervisorsDropdown.value.find(e => e.value === s)
              if (supervisorFound) {
                const supervisorLocationIds = supervisorFound.locationIds
                for (const location of eventLocations) {
                  if (locationFilter.value.length && !locationFilter.value.includes(location._id)) continue
                  if (supervisorLocationIds.includes(location._id)) {
                    const name = supervisorFound.title
                    const supervisorLocationNames = supervisorFound.locationNames?.join(" / ")
                    updateSupervisorData(supervisorData, name, supervisorLocationNames, status)
                    break
                  }
                }
              }
            })
          } else {
            for (const supervisor of supervisorsDropdown.value) {    
              const supervisorLocationIds = supervisor.locationIds
              for (const location of eventLocations) {
                if (locationFilter.value.length && !locationFilter.value.includes(location._id)) continue
                if (supervisorLocationIds.includes(location._id)) {
                  const name = supervisor.title
                  const supervisorLocationNames = supervisor.locationNames?.join(" / ")
                  updateSupervisorData(supervisorData, name, supervisorLocationNames, status)
                  break
                }
              }
            }
          }
        }

        // Update supervisors 'observed' property for events with confirmations
        if (element.confirmation && element.confirmation.supervisor) {
          if (supervisorFilter.value.length && !supervisorFilter.value.includes(element.confirmation.supervisor._id)) continue
          const name = element.confirmation.supervisor.name
          const supervisorLocations = element.confirmation.supervisor.locations?.map(e => e.location).join(" / ")
          updateSupervisorData(supervisorData, name, supervisorLocations, "Observadas")
        }
      }

      // Set deleted events data for Excel download
      deletedEventsData.sort(function (a, b) {
        return a.start - b.start
      })
      deletedEvents.value = deletedEventsData

      // Parse supervisor data for Excel download
      let supervisorDataExcel = []

      for (const supervisor in supervisorData) {
        supervisorDataExcel.push({
          supervisor: supervisor,
          location: supervisorData[supervisor].location,
          compliance: compliance(supervisorData[supervisor]),
          done: supervisorData[supervisor].Realizadas,
          pending: supervisorData[supervisor].Pendientes,
          overdue: supervisorData[supervisor].Vencidas,
          observed: supervisorData[supervisor].Observadas,
        })
      }

      supervisorDataExcel.sort(function (a, b) {
        if (b.overdue !== a.overdue) return b.overdue - a.overdue
        if (b.pending !== a.pending) return b.pending - a.pending
        return a.done - b.done
      })

      supervisors.value = supervisorDataExcel;
      
      // Set tracker data
      trackerData.value = {
        title: computed(() => i18n.t("Cumplimiento")),
        lastDays: ["Acumulado"],
        total:
          _globalCompliance.Pendientes +
          _globalCompliance.Realizadas +
          _globalCompliance.Vencidas,
        done: _globalCompliance.Realizadas,
        pending: _globalCompliance.Pendientes,
        overdue: _globalCompliance.Vencidas,
      };

      // Set progress bar data
      const actualProgress = trackerData.value.total
        ? Math.round(trackerData.value.done / trackerData.value.total * 100)
        : 0

      const theoreticalProgress = endFilter < now
        ? 100
        : startFilter > now
          ? 0
          : Math.round(dateDiffInDays(startFilter, now) / dateDiffInDays(startFilter, endFilter) * 100)

      progressCompliance.value = {
        actual: actualProgress,
        theoretical: theoreticalProgress,
      }

      // Set location compliance data
      for (const location in _locationCompliance) {
        _locationCompliance[location].total = _locationCompliance[location].done + _locationCompliance[location].pending + _locationCompliance[location].overdue
        _locationCompliance[location].compliance = _locationCompliance[location].total ? Math.round(_locationCompliance[location].done / _locationCompliance[location].total * 1000) / 10 : 0
      }

      let locationComplianceDataSorted = Object.entries(_locationCompliance)

      locationComplianceDataSorted.sort(function (a, b) {
        if (b[1].compliance === 0 && a[1].compliance === 0) return a[1].total - b[1].total
        return b[1].compliance - a[1].compliance
      })

      let locationComplianceLabels = []
      let locationComplianceValues = []
      let locationComplianceValuesDone = []
      let locationComplianceValuesPending = []
      let locationComplianceValuesOverdue = []
      let locationComplianceValuesPendingNegative = []
      let locationComplianceValuesOverdueNegative = []
      let locationIdValues = []

      locationComplianceDataSorted.forEach(e => {
        locationComplianceLabels.push(e[0])
        locationComplianceValues.push(e[1].compliance)
        locationComplianceValuesDone.push(e[1].done)
        locationComplianceValuesPending.push(e[1].pending)
        locationComplianceValuesOverdue.push(e[1].overdue)
        locationComplianceValuesPendingNegative.push(-1 * e[1].pending)
        locationComplianceValuesOverdueNegative.push(-1 * e[1].overdue)
        locationIdValues.push(e[1].id)
      })

      locationCompliance.value = {
        labels: locationComplianceLabels,
        data: {
          compliance: locationComplianceValues,
          done: locationComplianceValuesDone,
          pending: locationComplianceValuesPending,
          overdue: locationComplianceValuesOverdue,
        },
        additionalInfo: {
          locationIds: locationIdValues,
        }
      };

      // Set global compliance data
      globalCompliance.value = {
        labels: locationComplianceLabels,
        done: locationComplianceValuesDone,
        pending: locationComplianceValuesPendingNegative,
        overdue: locationComplianceValuesOverdueNegative,
      };
    }

    function updateSupervisorData(object, name, locations, status) {    
      if (!object[name]) {
        object[name] = {
          location: locations,
          Realizadas: 0,
          Vencidas: 0,
          Pendientes: 0,
          Observadas: 0,
        };
      }

      if (status === "Realizadas") {
        object[name]["Realizadas"] += 1;
      } else if (status === "Pendientes") {
        object[name]["Pendientes"] += 1;
      } else if (status === "Vencidas") {
        object[name]["Vencidas"] += 1;
      } else if (status === "Observadas") {
        object[name]["Observadas"] += 1;
      }
    }

    function compliance(supervisor) {
      const total = supervisor.Realizadas + supervisor.Vencidas + supervisor.Pendientes;
      if (!total) return "N/A"
      const ratio = (supervisor.Realizadas / total) * 100;

      return `${ratio.toFixed(1)}%`;
    }

    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      roleFilter.value = data.roleFilter;
      processFilter.value = data.processFilter;
      supervisorFilter.value = data.supervisorFilter;
      dateRangeFilter.value = data.dateRangeFilter;
      zoneFilter.value = data.zoneFilter;
      agencyFilter.value = data.agencyFilter;

      listConfirmations();
    }

    function updateSupervisors(data) {
      if (!supervisorsDropdown.value.length) {
        supervisorsDropdown.value = data;
        listConfirmations();
      }
    }

    onMounted(() => {
      listConfirmations()
    })

    return {
      confirmations,
      supervisors,
      compliance,
      trackerData,
      locationCompliance,
      globalCompliance,
      progressCompliance,
      updateFilter,
      updateSupervisors,
      confirmationFields,
      supervisorFields,
      isLoading,
      qEvents,
      zone_agency_filter,
      deletedEvents,
      deletedEventsFields,
      tableHeaders,
    };
  },
};
</script>
